<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <div class="title">
          <span>01</span>
          <h3>人才理念</h3>
          <p>Talent Concept</p>
        </div>
        <p>
          长春凯达发展有限公司作为长春汽车经济技术开发区区属大型国有独资企业，充分认识到国有企业人才队伍建设的重要性和紧迫性，秉承<span
            class="red"
            >“尊重人才、创造价值、追求卓越”</span
          >的人才理念，全面落实<span class="red">“人才强企”</span
          >战略，加快确立人才优先发展战略布局，努力建设一支数量充足、素质优良、结构合理、效能优先、具有市场开拓精神和管理创新能力的高水平人才队伍，为不断壮大国有经济提供强有力的人才保障和智力支持。
        </p>
        <p><b class="red">尊重人才</b></p>
        <p>
          凯达公司坚持尊重人才价值，重视人才培养，鼓励人才创造，充分发挥员工专长，加大在“引才、留才、育才、用才”等关键环节上的机制创新，以“重天下英才而用之”的气魄和大气、开放、包容的姿态，向社会各界人才展开热情的怀抱，打造“近悦远来”
          的人才聚集高地。
        </p>
        <p><b class="red">创造价值</b></p>
        <p>
          凯达公司致力为员工搭建实现职业梦想、体现人生价值的最佳舞台，实行以岗位价值、个人能力和绩效考核为基础的薪酬管理体系，充分发掘人才潜力，为优秀人才提供广阔的发展空间、完善的培养机制和极具竞争力的薪酬福利待遇，鼓励员工勇于突破自我、创造不凡价值、实现自我超越。
        </p>
        <p><b class="red">追求卓越</b></p>
        <p>
          凯达公司坚持“高门槛、高起点、高标准、高要求”，高薪招聘双一流高校优秀毕业生，引入行业顶尖人才，积极对标世界一流企业，持续推进科技创新，重视凯达品质、树立凯达目标、
          展示凯达形象，立志打造一支具有国际化视野和优秀管理能力、勇于开拓创新和富有社会责任感的高水平管理队伍，以人才的力量推动企业发展壮大，成就凯达未来。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: { Crumb, Banner },
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner3.jpg"),
    };
  },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .content {
      color: #666;
      .title {
        background: url(../../assets/images/bg-title.png) no-repeat 0 0;
        width: 255px;
        height: 50px;
        color: #fff;
        margin-bottom: 20px;
        padding: 15px 20px;
        span {
          display: block;
          float: left;
          width: 50px;
          height: 50px;
          font-size: 24px;
          color: #eb2737;
          text-align: center;
          font-weight: bold;
          line-height: 50px;
          margin-right: 10px;
        }
        h3 {
          font-size: 20px;
          line-height: 30px;
          font-weight: normal;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      p {
        margin-bottom: 1em;
      }
      .red {
        color: #eb2737;
      }
      .align-right {
        text-align: right;
      }
    }
  }
}
</style>
